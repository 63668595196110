
import { flutterHapticFeedback } from "apps.modules.FlutterJavaScriptChannels.ts";

async function sleep(ms: number): Promise<void> {
    await new Promise(resolve => setTimeout(resolve, ms));
}

export class Haptics {
    // default
    static async light(): Promise<void> {
        flutterHapticFeedback("LightImpact");
    }
    static async medium(): Promise<void> {
        flutterHapticFeedback("MediumImpact");
    }
    static async heavy(): Promise<void> {
        flutterHapticFeedback("HeavyImpact");
    }
    static async selection(): Promise<void> {
        flutterHapticFeedback("SelectionClick");
    }
    static async vibrate(): Promise<void> {
        flutterHapticFeedback("Vibrate");
    }

    // custom
    static async success(): Promise<void> {
        await this.light();
        await sleep(100);
        await this.light();
        await sleep(100);
        await this.heavy();
    }
    static async warning(): Promise<void> {
        await this.medium();
        await sleep(150);
        await this.medium();
    }
    static async error(): Promise<void> {
        await this.heavy();
        await sleep(100);
        await this.heavy();
        await sleep(100);
        await this.light();
    }
}
